import { EventEmitter } from 'events';
import hash from 'hash-sum';

import { filterOutNullable } from '@gitbook/util-ts';

const SET_EVENT = Symbol('set');

/**
 * Result when safely getting the value from cache.
 */
export type SuspenseSafeResult<Result> =
    | {
          state: SuspenseCacheEntryState.LIVE | SuspenseCacheEntryState.CACHED;
          result: Result;
          error?: undefined;
          pending?: Promise<Result>;
          lastUpdateKey?: number;
      }
    | {
          state: SuspenseCacheEntryState.FAILED;
          result?: undefined;
          error: Error;
          pending?: Promise<Result>;
          lastUpdateKey?: number;
      };

/**
 * Entry in the cache.
 */
export type SuspenseCacheResult<Result> =
    | SuspenseSafeResult<Result>
    | {
          state: SuspenseCacheEntryState.PENDING;
          result?: undefined;
          error?: undefined;
          pending: Promise<Result>;
          lastUpdateKey?: number;
      };

/**
 * Enum of state a cache entry can have.
 */
export enum SuspenseCacheEntryState {
    LIVE = 'live',
    PENDING = 'pending',
    CACHED = 'cached',
    FAILED = 'failed',
}

/**
 * Instance of a cache.
 */
export interface SuspenseCache<Input, Result> {
    getKey: (input: Input) => string;

    /**
     * Clear the entry in the cache for an input.
     */
    clear: (input: Input) => void;

    /**
     * Clear a cache entry using its raw key.
     */
    clearByKey: (key: string) => void;

    /**
     * Clear the entire cache.
     */
    clearAll: () => void;

    /**
     * Write a value directly in the cache.
     */
    write: (
        input: Input,
        r: Result,
        state?: SuspenseCacheEntryState.LIVE | SuspenseCacheEntryState.CACHED
    ) => void;

    /**
     * Write a value directly in the cache using its raw key.
     */
    writeWithKey: (
        key: string,
        r: Result,
        state?: SuspenseCacheEntryState.LIVE | SuspenseCacheEntryState.CACHED
    ) => void;

    /**
     * Read values from the upstream and store them in the cache.
     */
    readMultiple: (inputs: readonly Input[]) => Result[];

    /**
     * Read a value from the upstream and store it in the cache.
     */
    read: (input: Input) => Result;

    /**
     * Get the state of the cache for an input.
     * It doesn't read the value if it's not cached.
     */
    getCacheState: (input: Input) => undefined | SuspenseCacheResult<Result>;

    /**
     * Read a value from the cache.
     */
    readCacheState: (input: Input) => SuspenseCacheResult<Result>;

    /**
     * Return a hash for an array of input, used to compare if one of the value changed.
     * (order is ignored).
     */
    readCacheUpdatesKey: (inputs: readonly Input[], withStates?: boolean) => string;

    /**
     * Read a value from the upstream and store it in the cache.
     */
    readAsync: (input: Input) => Promise<Result>;

    /**
     * Read multiple value from the upstream and store it in the cache.
     */
    readMultipleAsync: (inputs: readonly Input[]) => Promise<Result[]>;

    /**
     * Read values from the upstream and ignore error (return).
     */
    readMultipleSafe: (inputs: readonly Input[]) => Array<SuspenseSafeResult<Result>>;

    /**
     * Read a value, and ignore error (return)
     */
    readSafe: (input: Input) => SuspenseSafeResult<Result>;

    /**
     * Fetch a value from the upstream and store it in the cache.
     * It resets the state in the key to pending while fetching.
     */
    fetch: (input: Input) => Promise<Result>;

    /**
     * Fetch a value from the upstream and update the cache once it's done.
     * It should be used when updating cached values.
     */
    refetch: (input: Input) => Promise<void>;

    /**
     * Serialize the cache into JSON.
     */
    toJS: () => object;

    /**
     * Deserialize the cache from JSON.
     */
    fromJS: (data: object) => void;

    /**
     * Subscribe to updates done on an input.
     * Returns a callback to unsubscribe.
     */
    subscribe: (input: Input, callback: () => void) => () => void;

    /**
     * Subscribe to updates done on multiple inputs.
     * Returns a callback to unsubscribe.
     */
    subscribeMultiple: (input: readonly Input[], callback: () => void) => () => void;
}

/**
 * An effect to run on the cache.
 */
export type SuspenseCacheEffect<Result> = (effect: {
    hasKey: (key: string) => boolean;
    getKey: (
        key: string,
        serialized: boolean
    ) => { state: SuspenseCacheEntryState; value: any } | undefined;
    setKey: (
        key: string,
        entry: {
            state?: SuspenseCacheEntryState.LIVE | SuspenseCacheEntryState.CACHED;
            value: any;
            serialized?: boolean;
        }
    ) => void;

    /**
     * When the cache is reading a key for the first time
     * Returns undefined if the value could not be initialized from the effect,
     * otherwise returns the initial value and the time it was set (used to check expired values).
     */
    onInitializeKey: (
        callback: (key: string) => Promise<{ result: Result; setAt: number } | undefined>
    ) => void;
    /** When a key is updated in the cache */
    onSet: (callback: (key: string) => void) => void;
}) => (() => void) | void;

/**
 * Create a cache for react suspense (it throws an error while loading).
 * Alternative to react-cache while it's still broken.
 */
export function createCache<Input, Result>(spec: {
    /**
     * Lookup a value from an input.
     */
    getValue: (input: Input) => Promise<Result> | Result;

    /**
     * Compute the cache key from an input.
     */
    getKey?: (input: Input) => string;

    /**
     * Compare two results
     */
    compare?: (a: Result, b: Result) => boolean;

    /**
     * Deserialize from JSON.
     */
    fromJS?: (value: any) => Result;

    /**
     * Serialize to JSON.
     */
    toJS?: (value: Result) => any;

    /**
     * TTL for an entry in the cache (in ms).
     * 0 means no TTL.
     */
    ttl?: number | ((value: Result) => number);

    effects?: SuspenseCacheEffect<Result>[];
}): SuspenseCache<Input, Result> {
    const {
        getValue,
        getKey = (input) => (typeof input !== 'undefined' ? createCacheKey(input) : ''),
        compare = (a, b) => a === b,
    } = spec;

    // Array of all effects initializers for keys, called during the first fetch of a key.
    const onInitializeKeyEffects: Array<
        (key: string) => Promise<{ result: Result; setAt: number } | undefined>
    > = [];
    // Set of all keys that have already been lazy initialized from the effects so
    // we don't run them again.
    const initializedValues = new Set<string>();

    const cache = new Map<string, SuspenseCacheResult<Result> & { setAt: number }>();
    const events = new EventEmitter();

    let ID = 0;
    function genID(): number {
        ID += 1;
        return ID;
    }

    function getCacheTTL(value: Result): number {
        if (!spec.ttl) {
            return 0;
        }

        if (typeof spec.ttl === 'number') {
            return spec.ttl;
        }

        return spec.ttl(value);
    }

    function hasExpired(value: { result: Result; setAt: number }): boolean {
        let isExpired = false;
        const ttl = getCacheTTL(value.result);

        if (ttl < 0) {
            isExpired = true;
        } else if (ttl > 0) {
            isExpired = Date.now() - value.setAt > ttl;
        }

        return isExpired;
    }

    function hasInCache(key: string): boolean {
        const cacheValue = cache.get(key);
        if (!cacheValue) {
            return false;
        }

        let isExpired = false;

        if (
            cacheValue.state === SuspenseCacheEntryState.LIVE ||
            cacheValue.state === SuspenseCacheEntryState.CACHED
        ) {
            isExpired = hasExpired(cacheValue);
        }

        if (isExpired) {
            clearByKey(key);
        }

        return !isExpired;
    }

    function getInCache(key: string): SuspenseCacheResult<Result> | undefined {
        const isInCache = hasInCache(key);
        if (!isInCache) {
            return undefined;
        }

        return cache.get(key);
    }

    function setInCache(key: string, value: SuspenseCacheResult<Result> & { setAt?: number }) {
        cache.set(key, {
            setAt: Date.now(),
            ...value,
        });
    }

    function writeWithKey(
        key: string,
        result: Result,
        state:
            | SuspenseCacheEntryState.LIVE
            | SuspenseCacheEntryState.CACHED = SuspenseCacheEntryState.LIVE
    ) {
        const previous = getInCache(key);

        const hasResultChanged = !(
            previous &&
            (previous.state === SuspenseCacheEntryState.LIVE ||
                previous.state === SuspenseCacheEntryState.CACHED) &&
            compare(result, previous.result)
        );

        // Skip the update if nothing is changing
        if (!hasResultChanged && previous.state === state && !previous.pending) {
            return;
        }

        setInCache(key, {
            state,
            // Preserve previous value for identity check when only the state changed
            result: hasResultChanged ? result : previous.result,
            // If only the state changed, we don't need to update the key
            lastUpdateKey: hasResultChanged || !previous ? genID() : previous.lastUpdateKey,
        });
        events.emit(SET_EVENT, key);
    }

    function write(
        input: Input,
        result: Result,
        state?: SuspenseCacheEntryState.LIVE | SuspenseCacheEntryState.CACHED
    ) {
        const key = getKey(input);
        writeWithKey(key, result, state);
    }

    function subscribeMultiple(inputs: Input[], callback: () => void): () => void {
        const keys = inputs.map((input) => getKey(input));

        const onSet = (key) => {
            if (keys.includes(key)) {
                callback();
            }
        };

        events.addListener(SET_EVENT, onSet);

        return () => {
            events.removeListener(SET_EVENT, onSet);
        };
    }

    function subscribe(input: Input, callback: () => void): () => void {
        return subscribeMultiple([input], callback);
    }

    function clearAll() {
        const keys = [...cache.keys()];
        cache.clear();
        initializedValues.clear();
        keys.forEach((key) => {
            events.emit(SET_EVENT, key);
        });
    }

    function clear(input: Input) {
        const key = getKey(input);
        clearByKey(key);
    }

    function clearByKey(key: string) {
        cache.delete(key);
        events.emit(SET_EVENT, key);
    }

    // Handle error
    function handleError(cacheKey: string, error: Error | Promise<any>): Promise<any> {
        // Handle cache using another cache.
        if (error instanceof Promise) {
            cache.delete(cacheKey);
            throw error;
        }

        setInCache(cacheKey, { state: SuspenseCacheEntryState.FAILED, error });

        return Promise.reject(error);
    }

    // Fetch the value, handle sync/async case to avoid throwing for sync cases.
    function fetch(
        input: Input,
        cacheKey: string = getKey(input),
        resetToPending: boolean = true
    ): Promise<Result> {
        const currentState = getInCache(cacheKey);
        if (currentState?.pending) {
            return currentState.pending;
        }

        let r: Promise<Result> | Result;
        let hasBeenInitialized = false;
        try {
            if (initializedValues.has(cacheKey)) {
                // The value has already been initialized once, we can directly fetch it
                // for the next call.
                r = getValue(input);
            } else if (onInitializeKeyEffects.length === 0) {
                // No initializers to run, we mark the value as initialized and fetch it
                initializedValues.add(cacheKey);
                r = getValue(input);
            } else {
                // Otherwise we run each initialize effect and try to get
                // a non-expired value
                initializedValues.add(cacheKey);

                r = Promise.all(onInitializeKeyEffects.map((callback) => callback(cacheKey))).then(
                    (results) => {
                        const nonExpiredValue = results.find(
                            (result) => !!result && !hasExpired(result)
                        );

                        if (!nonExpiredValue) {
                            // No effect returned a non-expired value, we fallback to the actual getValue
                            return getValue(input);
                        }

                        // At least one effect has a non-expired initial value,
                        // we can set it in the cache
                        const serializedResult = spec.fromJS
                            ? spec.fromJS(nonExpiredValue.result)
                            : nonExpiredValue.result;

                        setInCache(cacheKey, {
                            state: SuspenseCacheEntryState.CACHED,
                            result: serializedResult,
                            setAt: nonExpiredValue.setAt,
                        });

                        hasBeenInitialized = true;
                        return serializedResult;
                    },
                    // Ignore errors from the initializers effects as it's too risky
                    // we fallback to using the actual getValue
                    () => getValue(input)
                );
            }
        } catch (error) {
            return handleError(cacheKey, error);
        }

        if (r instanceof Promise) {
            const pending = r.then(
                (result) => {
                    writeWithKey(cacheKey, result);
                    return result;
                },
                (error) => handleError(cacheKey, error)
            );

            if (currentState && !resetToPending) {
                setInCache(cacheKey, { ...currentState, pending });
            } else if (
                // If the value has been initialized, it will be available in the cache already
                // so we don't suspend. Otherwise, we set it as pending until it's resolved.
                !hasBeenInitialized
            ) {
                setInCache(cacheKey, { state: SuspenseCacheEntryState.PENDING, pending });
            }

            return pending;
        } else {
            writeWithKey(cacheKey, r);
            return Promise.resolve(r);
        }
    }

    function readMultipleSafe(inputs: readonly Input[]): Array<SuspenseSafeResult<Result>> {
        const states = inputs.map((input) => {
            const cacheKey = getKey(input);

            if (!hasInCache(cacheKey)) {
                fetch(input);
            }

            return getInCache(cacheKey);
        });

        const pendings = states
            .map((state) =>
                state?.state === SuspenseCacheEntryState.PENDING ? state.pending : null
            )
            .filter(filterOutNullable);

        if (pendings.length > 0) {
            throw Promise.all(
                pendings.map((pending) =>
                    pending.catch(() => {
                        // Ignore errors in the thrown promise to force a re-render
                        // otherwise SSR will fail even for readSafe calls
                    })
                )
            );
        }

        // @ts-ignore
        return states;
    }

    function readMultiple(inputs: readonly Input[]): Result[] {
        const states = readMultipleSafe(inputs);

        const errors = states.map((state) => 'error' in state && state.error).filter(Boolean);

        if (errors.length > 0) {
            throw errors[0];
        }

        return states.map((state) => {
            if (
                state.state !== SuspenseCacheEntryState.CACHED &&
                state.state !== SuspenseCacheEntryState.LIVE
            ) {
                throw new Error(`Unexpected state: ${state.state}`);
            }
            return state.result;
        });
    }

    function getCacheState(input: Input): undefined | SuspenseCacheResult<Result> {
        const cacheKey = getKey(input);
        return getInCache(cacheKey);
    }

    function readCacheState(input: Input): SuspenseCacheResult<Result> {
        const cacheKey = getKey(input);

        if (!hasInCache(cacheKey)) {
            fetch(input);
        }

        const state = getInCache(cacheKey);
        if (!state) {
            throw new Error('Cache state was expected after fetch() call');
        }

        return state;
    }

    function read(key: Input): Result {
        return readMultiple([key])[0];
    }

    async function readAsync(input: Input): Promise<Result> {
        const cacheKey = getKey(input);
        const state = getInCache(cacheKey);

        if (!state) {
            return await fetch(input);
        } else {
            if (state.state === SuspenseCacheEntryState.PENDING) {
                return state.pending;
            } else if (state.state === SuspenseCacheEntryState.FAILED) {
                throw state.error;
            }

            return state.result;
        }
    }

    async function readMultipleAsync(inputs: readonly Input[]): Promise<Result[]> {
        return Promise.all(inputs.map((input) => readAsync(input)));
    }

    function readCacheUpdatesKey(inputs: readonly Input[], withStates: boolean = false): string {
        const keys = inputs
            .map((input) => {
                const cacheKey = getKey(input);
                const entry = getInCache(cacheKey);

                return [
                    cacheKey,
                    `${entry ? entry.lastUpdateKey || 0 : -1}:${withStates ? entry?.state : '-'}`,
                ] as const;
            })
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map((entry) => `${entry[0]}{${entry[1]}}`)
            .join('/');

        return keys;
    }

    function readSafe(input: Input): SuspenseSafeResult<Result> {
        return readMultipleSafe([input])[0];
    }

    async function refetch(input: Input): Promise<void> {
        await fetch(input, getKey(input), false);
    }

    function toJS(): object {
        const result = {};
        cache.forEach((entry, cacheKey) => {
            if (
                entry.state === SuspenseCacheEntryState.CACHED ||
                entry.state === SuspenseCacheEntryState.LIVE
            ) {
                result[cacheKey] = spec.toJS ? spec.toJS(entry.result) : entry.result;
            }
        });

        return result;
    }

    function fromJS(data: object) {
        Object.keys(data).forEach((key) => {
            setInCache(key, {
                state: SuspenseCacheEntryState.CACHED,
                result: spec.fromJS ? spec.fromJS(data[key]) : data[key],
            });
        });
    }

    // Initialize registered effects
    (spec.effects || []).forEach((effect) => {
        effect({
            getKey: (key, serialized) => {
                const entry = getInCache(key);
                if (
                    !entry ||
                    (entry.state !== SuspenseCacheEntryState.CACHED &&
                        entry.state !== SuspenseCacheEntryState.LIVE)
                ) {
                    return undefined;
                }

                const result = serialized && spec.toJS ? spec.toJS(entry.result) : entry.result;

                return {
                    state: entry.state,
                    value: result,
                };
            },
            setKey: (key, entry) => {
                const result = (
                    entry.serialized && spec.fromJS ? spec.fromJS(entry.value) : entry.value
                ) as Result;
                setInCache(key, {
                    state: entry.state || SuspenseCacheEntryState.LIVE,
                    result,
                });
            },
            hasKey: (key) => hasInCache(key),
            onSet: (callback) => {
                events.addListener(SET_EVENT, (key) => {
                    callback(key);
                });
            },
            onInitializeKey: (callback) => {
                onInitializeKeyEffects.push(callback);
            },
        });
    });

    return {
        getKey,
        write,
        writeWithKey,
        clear,
        clearByKey,
        clearAll,
        readMultiple,
        readMultipleSafe,
        readMultipleAsync,
        readCacheUpdatesKey,
        getCacheState,
        readCacheState,
        read,
        readAsync,
        readSafe,
        refetch,
        fetch,
        toJS,
        fromJS,
        subscribe,
        subscribeMultiple,
    };
}

/**
 * Helper to create a cache key from an input.
 */
export function createCacheKey(input: any): string {
    if (typeof input === 'string') {
        return input;
    }

    return hash(input);
}
